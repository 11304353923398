<template>
  <main>
    <div class="min-h-[100dvh] flex items-center justify-center w-full">
      <div class="max-w-[25rem] py-12 w-full">
        <a
          :href="`https://${useRuntimeConfig().public.productionDomain}`"
          class="flex justify-center mb-8"
        >
          <MainLogo class="w-44 h-auto" />
        </a>

        <slot />
      </div>
    </div>
  </main>
</template>

<script setup>
import constants from '~/api/shared/_constants.js';

const route = useRoute();
const title = computed(() => {
  if (route?.meta?.title) {
    return `${typeof route.meta.title === 'function' ? route.meta.title() : route.meta.title} | Attendu`;
  }

  return 'Attendu';
});
useSeoMeta({
  title,
  ogTitle: title.value && title, // title.value here is hackfix
});

if (useWorkspaceStore().integrations?.customDomain?.url && useRequestURL().host === useWorkspaceStore().integrations?.customDomain?.url) {
  navigateTo('/welcome');
} else if (!useWorkspaceStore().domain && constants.RESERVED_DOMAINS.includes(useRequestURL().host?.split('.')?.[0])) {
  navigateTo(`https://www.${useRuntimeConfig().public.productionDomain}`, { external: true });
} else if (useWorkspaceStore().loadFailed) {
  throw createError({
    statusCode: 404,
    message: useT('abc1082', { domain: useRequestURL().host }),
  });
}

useHead({
  meta: [
    {
      name: 'robots',
      content: 'noindex',
    },
  ],
});
</script>
